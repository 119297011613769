<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from "axios";

export default {
  page: {
    title: "公司資料設定",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "公司資料設定",
      items: [
        {
          text: "公司資料設定",
          href: "/company",
        },
      ],
      basic_form_submitted: false,
      form_errors: {},
      result_msg: null,
      result_msg_variant: "danger",
      company: {},
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  methods: {
    async get_company() {
      const result = await axios.get(
        process.env.VUE_APP_BACKEND_URL + "company"
      );

      if (result.data.status == 0) {
        return (this.result_msg = result.data.msg);
      }
      this.company = result.data.data;
    },

    async update_company_api() {
      this.basic_form_submitted = true;
      const result = await axios
        .post(process.env.VUE_APP_BACKEND_URL + "company", {
          name: this.company.name,
          tax_no: this.company.tax_no,
          tel: this.company.tel,
          email: this.company.email,
          address: this.company.address,
          line: this.company.line,
          fb: this.company.fb,
          ig: this.company.ig,
        })
        .catch((error) => {
          this.result_msg_variant = "danger";
          this.result_msg = error.response.data.msg;
          this.form_errors = error.response.data.errors;
        });

      if (result.data.status == 0) {
        return (this.result_msg = result.data.msg);
      }
      this.company = result.data.data;
      this.result_msg = result.data.msg;
      this.result_msg_variant = "success";
      this.basic_form_submitted = false;
    },

    update_company_advanced_api() {
      this.basic_form_submitted = true;
      axios
        .post(`${process.env.VUE_APP_BACKEND_URL}company/advanced`, {
          ga: this.company.ga,
          fb_pixel: this.company.fb_pixel,
        })
        .then((result) => {
          if (result.data.status == 0) {
            return (this.result_msg = result.data.msg);
          }
          this.company = result.data.data;
          this.result_msg = result.data.msg;
          this.result_msg_variant = "success";
          this.basic_form_submitted = false;
        })
        .catch((error) => {
          this.result_msg_variant = "danger";
          this.result_msg = error.response.data.msg;
          this.form_errors = error.response.data.errors;
        });
    },
  },
  mounted() {
    // get company
    this.get_company();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert v-model="result_msg" :variant="result_msg_variant" dismissible>
      {{ result_msg }}
    </b-alert>
    <div class="row">
      <div class="col-xxl-6">
        <div class="card h-100">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">基本資訊</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <div class="live-preview">
              <form action="javascript:void(0);">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="companyName" class="form-label"
                        >公司名稱</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="請輸入公司完整名稱"
                        id="companyName"
                        name="name"
                        v-model="company.name"
                        :class="{
                          'is-invalid':
                            basic_form_submitted && form_errors.name,
                        }"
                      />
                      <div
                        v-for="(msg, index) in form_errors.name"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span>{{ msg }}</span>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="taxNumber" class="form-label">統一編號</label>
                      <input
                        name="tax_no"
                        type="text"
                        class="form-control invalid"
                        placeholder="請輸入統編"
                        id="taxNumber"
                        v-model="company.tax_no"
                        :class="{
                          'is-invalid':
                            basic_form_submitted && form_errors.tax_no,
                        }"
                      />
                      <div
                        v-for="(msg, index) in form_errors.tax_no"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span>{{ msg }}</span>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="phone" class="form-label">電話</label>
                      <input
                        type="tel"
                        class="form-control"
                        placeholder="公司聯絡電話"
                        id="phone"
                        name="tel"
                        v-model="company.tel"
                        :class="{
                          'is-invalid': basic_form_submitted && form_errors.tel,
                        }"
                      />
                      <div
                        v-for="(msg, index) in form_errors.tel"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span>{{ msg }}</span>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="email" class="form-label">信箱</label>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="公司聯絡信箱"
                        id="email"
                        name="email"
                        v-model="company.email"
                        :class="{
                          'is-invalid':
                            basic_form_submitted && form_errors.email,
                        }"
                      />
                      <div
                        v-for="(msg, index) in form_errors.email"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span>{{ msg }}</span>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label for="address" class="form-label">地址</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="公司詳細地址"
                        id="address"
                        name="address"
                        v-model="company.address"
                        :class="{
                          'is-invalid':
                            basic_form_submitted && form_errors.address,
                        }"
                      />
                      <div
                        v-for="(msg, index) in form_errors.address"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span>{{ msg }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label for="line" class="form-label">Line</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Line完整連結"
                        id="line"
                        name="line"
                        v-model="company.line"
                        :class="{
                          'is-invalid':
                            basic_form_submitted && form_errors.line,
                        }"
                      />
                      <div
                        v-for="(msg, index) in form_errors.line"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span>{{ msg }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label for="fb" class="form-label">FB Messenger</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="FB Messenger URL"
                        id="fb"
                        name="fb"
                        v-model="company.fb"
                        :class="{
                          'is-invalid': basic_form_submitted && form_errors.fb,
                        }"
                      />
                      <div
                        v-for="(msg, index) in form_errors.fb"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span>{{ msg }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label for="ig" class="form-label">Instagram</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Instagram"
                        id="ig"
                        name="ig"
                        v-model="company.ig"
                        :class="{
                          'is-invalid': basic_form_submitted && form_errors.ig,
                        }"
                      />
                      <div
                        v-for="(msg, index) in form_errors.ig"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span>{{ msg }}</span>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
            </div>
          </div>

          <div class="card-footer border-top-0">
            <div class="col-lg-12">
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="update_company_api"
                >
                  修改
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->

      <div class="col-xxl-6 mt-sm-4 mt-xxl-0">
        <div class="card h-100">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Logo</h4>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <div class="live-preview">
              <form action="javascript:void(0);" class="row g-3">
                <div class="col-12 text-center">
                  <img
                    src="@/assets/images/companies/img-1.png"
                    alt="Mac Image"
                    class="img-fluid"
                  />
                </div>
                <div class="col-12">
                  <input
                    class="form-control"
                    type="file"
                    id="formFile"
                    name="logo"
                  />
                  <p class="text-muted mt-2">
                    請使用 <code>180 × 180 px</code> 的
                    <code>.png</code> 去背圖片
                  </p>
                </div>
              </form>
            </div>
          </div>

          <div class="card-footer border-top-0">
            <div class="col-12">
              <div class="text-end">
                <button type="submit" class="btn btn-primary">提交圖片</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->

      <div class="col-xxl-6 mt-4 mb-4">
        <div class="card h-100">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">進階設定</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <div class="live-preview">
              <form action="javascript:void(0);">
                <div class="row">
                  <div class="col-md-12">
                    <label for="ga" class="form-label">GA分析</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="貼上評估ID"
                      id="ga"
                      v-model="company.ga"
                      :class="{
                        'is-invalid': basic_form_submitted && form_errors.ga,
                      }"
                    />
                    <div
                      v-for="(msg, index) in form_errors.ga"
                      :key="index"
                      class="invalid-feedback"
                    >
                      <span>{{ msg }}</span>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12 mt-3">
                    <label for="fb_pixel" class="form-label">FB像素</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="貼上像素ID"
                      id="fb_pixel"
                      v-model="company.fb_pixel"
                      :class="{
                        'is-invalid':
                          basic_form_submitted && form_errors.fb_pixel,
                      }"
                    />
                    <div
                      v-for="(msg, index) in form_errors.fb_pixel"
                      :key="index"
                      class="invalid-feedback"
                    >
                      <span>{{ msg }}</span>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
            </div>
          </div>

          <div class="card-footer border-top-0">
            <div class="col-lg-12">
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="update_company_advanced_api"
                >
                  修改
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!--end row-->
  </Layout>
</template>
